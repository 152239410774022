import React, { useState, useEffect, useCallback, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Container,
  Row,
  Form,
  Col,
  Card,
  Table,
  Button,
  Badge,
  ButtonGroup,
  Modal,
  FormLabel,
  ModalFooter,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Navbar from "../components/Navbar";
import { useSpring, animated } from "react-spring";
import Webcam from "react-webcam";
import QrReader from "react-qr-scanner";
import axios from "../utils/axios";
import NOIMG from "../assets/no-image.jpg";
import LOADING from "../assets/idle.png";
import LOGO2 from "../assets/logo3.png";
import QR from "../assets/qr.png";
import BEEP from "../assets/beep.mp3";
import SHOT from "../assets/camera.mp3";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import { getToken, getUser } from "../utils/Common";
import moment from "moment";
import { removeUserSession } from "../utils/Common";
import _ from "lodash";

function Dashboard() {
  const token = getToken();
  const user = getUser();

  const history = useHistory();
  const WebcamComponent = () => <Webcam />;
  const LO = user.logo_hotel;
  const doLogout = () => {
    removeUserSession();
    history.push("/");
  };
  const searchInputRef = useRef(null);
  const webcamFaceRef = useRef(null);
  const webcamIDCardRef = useRef(null);
  const webcamLuggageRef = useRef(null);
  const webcamFaceCompareRef = useRef(null);
  const webcamIDCompareRef = useRef(null);

  const [capturedFaceImage, setCapturedFaceImage] = useState(null);
  const [capturedIDImage, setCapturedIDImage] = useState(null);
  const [capturedLuggageImage, setCapturedLuggageImage] = useState(null);
  const [QRCodeCaptured, setQRCodeCaptured] = useState(null);

  const [capturedFaceCompareImage, setCapturedFaceCompareImage] =
    useState(null);
  const [capturedIDCompareImage, setCapturedIDCompareImage] = useState(null);

  const videoConstraints = {
    width: 420,
    height: 300,
    facingMode: "user",
  };

  const [searchGuest, setSearchGuest] = useState("");
  const [statusSystem, setStatusSystem] = useState("standby");
  const [sendQRButton, setSendQRButton] = useState(false);
  const [hotelList, setHotelList] = useState([]);
  const [guestInHouse, setGuestInHouse] = useState([]);
  const [depositeList, setDepositeList] = useState([]);
  const [showModalPrint, setShowModalPrint] = useState(false);
  const [loading, setLoading] = useState(false);
  const [depositeActive, setDepositeActive] = useState(true);
  const [releaseActive, setReleaseActive] = useState(false);

  const [guestID, setGuestID] = useState(null);
  const [guestInfo, setGuestInfo] = useState({
    guestName: "",
    roomNo: "",
    vehicleNo: "",
    info: "",
    whatsappNo: "",
    ci: "",
    co: "",
    linkqr: "",
  });

  //STATE TO SHOW IMAGE
  const [show, setShow] = useState(false);
  const [modalImage, setModalImage] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = (image) => {
    setModalImage(image);
    setShow(true);
  };

  const standardizePhoneNumber = (phoneNumber) => {
    return phoneNumber;
  };

  const filteredArray = depositeList.filter((item) => {
    const searchLower = searchGuest.toLowerCase();
    return (
      item.unique_num.toString().toLowerCase().includes(searchLower) ||
      item.guest_name.toLowerCase().includes(searchLower)
    );
  });

  const openQRScanner = () => {
    setStatusSystem("qrScan");
    setCapturedFaceImage(null);
    setCapturedIDImage(null);
    setCapturedLuggageImage(null);
    setQRCodeCaptured(null);
  };

  const sendQRtoGuest = async () => {
    try {
      const response = await axios.post(
        `/api/send/QR`,
        { QRCodeCaptured },
        { headers: { "content-type": "text/json" } }
      );
    
      if (response.data.error) {
        Swal.fire({
          icon: "error",
          title: "Invalid Number",
          text: "Please enter a valid phone number",
        });
      } else {
        Swal.fire({
          icon: "success",
          title: "Sent",
          text: "QR has been sent to the guest, make sure the guest receives the QR via WhatsApp",
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      // setTimeout(() => {
      //   // Reload the page
      //   window.location.reload();
      // }, 3000);
    }
  };

  const handleGenerateQR = async (e) => {
    e.preventDefault();
    try {
      const UNAME = user.name;
      const HID = user.hotel_id;
      // console.log("face imgae: ", capturedFaceImage);
      // console.log("ID imgae: ", capturedIDImage);
      // console.log("Luggage imgae: ", capturedLuggageImage);
      const response = await axios.post(
        `/api/submit/deposite`,
        {
          UNAME,
          HID,
          guestInfo,
          capturedFaceImage: capturedLuggageImage,
          capturedIDImage,
          capturedLuggageImage: capturedFaceImage,
        },
        { headers: { "content-type": "text/json" } }
      );

      setQRCodeCaptured(response.data);
    } catch (err) {
      console.log(err);

      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: err,
      });
    } finally {
      setSendQRButton(true);
      getListDeposite();
    }
  };

  const addNewItem = () => {
    // window.location.reload()
    setStatusSystem("guestface");
    setGuestInfo({
      guestName: "",
      roomNo: "",
      vehicleNo: "",
      info: "",
      whatsappNo: "",
    });
    setQRCodeCaptured(null);
    setCapturedFaceImage(null);
    setCapturedIDImage(null);
    setCapturedLuggageImage(null);
    setCapturedFaceCompareImage(null);
    setCapturedIDCompareImage(null);
    setGuestID(null);
  };

  const captureFace = () => {
    // console.log("capture face");
    const imageFace = webcamFaceRef.current.getScreenshot();
    if (imageFace) {
      const audio = new Audio(SHOT); // Replace with the actual path to your sound file
      audio.play();
    }
    setCapturedFaceImage(imageFace);

    if (guestID !== null) {
      setStatusSystem("formInformation");
    } else {
      setStatusSystem("guestID");
    }
  };

  const captureFaceCompare = () => {
    const imageFaceCompare = webcamFaceCompareRef.current.getScreenshot();
    if (imageFaceCompare) {
      const audio = new Audio(SHOT); // Replace with the actual path to your sound file
      audio.play();
    }
    setCapturedFaceCompareImage(imageFaceCompare);
    if (guestID !== null) {
      setStatusSystem("formInformation");
    } else {
      setStatusSystem("guestIDCompare");
    }
  };

  const captureIDCard = () => {
    // console.log("capture id card");
    const imageIDCard = webcamIDCardRef.current.getScreenshot();
    if (imageIDCard) {
      const audio = new Audio(SHOT); // Replace with the actual path to your sound file
      audio.play();
    }
    setCapturedIDImage(imageIDCard);

    if (guestID !== null) {
      setStatusSystem("formInformation");
    } else {
      setStatusSystem("guestLuggage");
    }
  };

  const captureIDCompare = () => {
    const imageIDCompare = webcamIDCompareRef.current.getScreenshot();
    if (imageIDCompare) {
      const audio = new Audio(SHOT); // Replace with the actual path to your sound file
      audio.play();
    }
    setCapturedIDCompareImage(imageIDCompare);
    setStatusSystem("formInformation");
  };

  const captureLuggageItem = () => {
    // console.log("capture luggage");
    const imageLuggageItem = webcamLuggageRef.current.getScreenshot();
    if (imageLuggageItem) {
      const audio = new Audio(SHOT); // Replace with the actual path to your sound file
      audio.play();
    }
    getGuestList();
    setCapturedLuggageImage(imageLuggageItem);
    setStatusSystem("formInformation");
  };

  const getGuestList = async () => {
    var HID = user.hotel_id;
    try {
      const guest = await axios.get(`/api/list/guest/inhouse/${user.hotel_id}`);
      const filteredData = guest.data.filter(
        (entry) => entry.GuestName !== null
      );
      // Convert GuestName to uppercase for sorting
      const uppercaseData = filteredData.map((entry) => ({
        ...entry,
        GuestName: entry.GuestName.toUpperCase(),
      }));
      const sortedData = uppercaseData.sort((a, b) =>
        a.GuestName.localeCompare(b.GuestName)
      );
     
      setGuestInHouse(sortedData);
    } catch (err) {
      console.log(err);
    } finally {
      console.log('DONE')
    }
  };

  const handleScan = async (data) => {
    if (data) {
      const audio = new Audio(BEEP); // Replace with the actual path to your sound file
      audio.play();
      // console.log(data.text);
      setStatusSystem("standby");

      try {
        await new Promise((resolve) => setTimeout(resolve, 500));

        const response = await axios.get(`/api/detail/deposite/${data.text}`);
        // console.log(response.data);

        if (response.data === null) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "The scanned data did not find a match in the system",
          });
        } else {
          // SET DATA TO STATE
          // console.log("The array is not empty.", response.data.linkqr);
          setCapturedFaceImage(response.data.guest_img);
          setCapturedIDImage(response.data.guest_ID_img);
          setCapturedLuggageImage(response.data.guest_item_img);
          setQRCodeCaptured(response.data.linkqr);
          setCapturedFaceCompareImage(response.data.guest_img_compare);
          setCapturedIDCompareImage(response.data.guest_ktp_compare);
          setGuestID(response.data.id_care);

          setGuestInfo({
            guestName: response.data.guest_name || "",
            roomNo: response.data.guest_room || "",
            vehicleNo: response.data.noPol || "",
            info: response.data.guest_remark || "",
            whatsappNo: response.data.phoneNo || "",
          });
        }
      } catch (err) {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "The scanned data did not find a match in the system",
        });
      } finally {
        setStatusSystem("formInformation");
      }
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  const getListDeposite = async () => {
    setLoading(true);
    setDepositeActive(true);
    setReleaseActive(false);
    try {
      const list = await axios.get(`/api/list/deposite/${user.hotel_id}`);

      // Modify the phoneNo property in each object
      const updatedList = list.data.map((item) => {
        if (item.phoneNo && item.phoneNo.startsWith("0")) {
          item.phoneNo = "+62" + item.phoneNo.slice(1);
        }
        return item;
      });
      // console.log("updatedList: ", updatedList);

      setDepositeList(updatedList);
    } catch (err) {
      console.log(err);
    } finally {
      // console.log('DONE')
      setLoading(false);
    }
  };

  const getListRelease = async () => {
    setLoading(true);
    setDepositeActive(false);
    setReleaseActive(true);
    try {
      const list = await axios.get(`/api/list/release/${user.hotel_id}`);

      setDepositeList(list.data);
    } catch (err) {
      console.log(err);
    } finally {
      //console.log("DONE");
      setLoading(false);
    }
  };

  const handleGuestInformation = (e) => {
    const selectedValue = JSON.parse(e.target.value);
    const guest = selectedValue.GuestName;
    const roomNo = selectedValue.roomNo;

    setGuestInfo((guestInfo) => ({
      ...guestInfo,
      guestName: guest,
      roomNo: roomNo,
    }));
  };

  const getDetailGuest = async (idguest) => {
    searchInputRef.current.focus();
    try {
      const response = await axios.get(`/api/detail/deposite/${idguest}`);

      // SET DATA TO STATE
      // console.log("detail guest: ", response.data);
      setCapturedFaceImage(response.data.guest_img); //guest_item_img : guest face
      setCapturedIDImage(response.data.guest_ID_img);
      setCapturedLuggageImage(response.data.guest_item_img); //guest_img (face) : guest item img
      setQRCodeCaptured(response.data.linkqr);
      setCapturedFaceCompareImage(response.data.guest_img_compare);
      setCapturedIDCompareImage(response.data.guest_ktp_compare);
      setGuestID(response.data.id_care);
      setGuestInfo((guestInfo) => ({
        ...guestInfo,
        guestName: response.data.guest_name || "",
        roomNo: response.data.guest_room || "",
        vehicleNo: response.data.noPol || "",
        info: response.data.guest_remark || "",
        whatsappNo: response.data.phoneNo || "",
        ci: response.data.checkin_item || "",
        co: response.data.checkout_item || "",
        linkqr: response.data.linkqr || "",
      }));
    } catch (err) {
      console.log(err);
    } finally {
      setStatusSystem("formInformation");
    }
  };

  const doUpdateDetailGuest = async (e) => {
    try {
      const response = await axios.post(
        `/api/update/deposite`,
        {
          guestID,
          guestInfo,
          capturedFaceImage,
          capturedIDImage,
          capturedLuggageImage,
          capturedFaceCompareImage,
          capturedIDCompareImage,
        },
        { headers: { "content-type": "text/json" } }
      );

      
    } catch (err) {
      console.log(err);

      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: err,
      });
    } finally {
      getDetailGuest(guestID);
      Swal.fire({
        icon: "success",
        title: "Update succesfully",
      }).then(() => {
        // window.location.reload();
        getGuestList();
      });
    }
  };

  const doReleaseItem = async () => {
    if (
      guestID !== null &&
      capturedFaceImage !== null &&
      capturedIDImage !== null &&
      capturedLuggageImage !== null &&
      QRCodeCaptured !== null &&
      capturedFaceCompareImage !== null &&
      capturedIDCompareImage !== null
    ) {
      const UNAME = user.name;
      try {
        const response = await axios.post(
          `/api/submit/release`,
          {
            UNAME,
            guestID,
            guestInfo,
            capturedFaceImage,
            capturedIDImage,
            capturedLuggageImage,
            capturedFaceCompareImage,
            capturedIDCompareImage,
          },
          { headers: { "content-type": "text/json" } }
        );

        // console.log(response.data);
        Swal.fire({
          icon: "success",
          title: "Release",
          text: "Item has been release to the guest, make sure the guest received the release bill ",
        });
      } catch (err) {
        console.log(err);

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err,
        });
      } finally {
        setTimeout(() => {
          // Reload the page
          window.location.reload();
        }, 3000);
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "there is something error with the data",
      });
    }
  };

  const doPrint = async () => {
    let printContents = document.getElementById("printArea").innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();
  };

  const debouncedGetListDeposite = _.debounce(getListDeposite, 200);
  const debouncedGetListRelease = _.debounce(getListRelease, 200);
  const debouncedGetGuestList = _.debounce(getGuestList, 200);

  useEffect(() => {
    searchInputRef.current.focus();
    debouncedGetListDeposite();
    debouncedGetGuestList();
  }, []);

  return (
    <div style={{ backgroundColor: "#E4C17D", height: "100vh" }}>
      <div
        className="mainContent p-0"
        style={{ backgroundColor: "#E4C17D", height: "100vh" }}
      >
        <Container fluid className="w-90 mt-0" style={{ height: "100vh" }}>
          <Row style={{ height: "100vh" }}>

            {/* Col for sidebar */}
            <Col
             
              className="ps-4 pe-3"
              style={{
                marginTop: "100px",
                paddingBottom: "100px",
                maxWidth: "24%",
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                
              }}
            >
              <div style={{ position: "fixed", top: 22, left: "5%" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center", // Center vertically
                    justifyContent: "center", // Center horizontally
                   
                  }}
                >
                  <div
                    style={{
                      borderRadius: 4,
                      padding: "4px 10px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={user.logo_hotel}
                      style={{
                        display: "block",
                        margin: "0 auto", // Center horizontally
                        width: "50px", // Set consistent width
                        height: "50px",
                      }}
                      alt="Hotel Logo"
                    />
                  </div>
                </div>
              </div>


              <Row
                style={{
                  marginBottom: "8px",
                }}
              >
                <Col style={{ paddingRight: "0px" }}>
                  <Card
                    style={{
                      width: "158px",
                      height: "auto",
                      marginBottom: "0px",
                      borderRadius: 15,
                      padding: "10px 12px 3px 12px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {capturedFaceImage ? (
                      <Card.Img
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          cursor: "pointer",
                          borderRadius: 10,
                        }}
                        variant="top"
                        src={capturedFaceImage}
                        onClick={() => handleShow(capturedFaceImage)}
                      />
                    ) : (
                      <Card.Img
                        style={{
                          maxWidth: "70%",
                          height: "auto",
                          cursor: "pointer",
                          borderRadius: 10,
                        }}
                        variant="top"
                        src={NOIMG}
                      />
                    )}
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip
                          id="tooltip-print"
                          style={{ fontSize: "10px" }}
                        >
                          Click to add/edit
                        </Tooltip>
                      }
                    >
                      <div
                        style={{
                          textAlign: "center",
                          padding: "0",
                          float: "left",
                        }}
                      >
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            display: "block",
                            marginTop: "3px",
                            cursor: "pointer",
                          }}
                          onClick={() => setStatusSystem("guestface")}
                        >
                          Face
                        </span>
                      </div>
                    </OverlayTrigger>
                  </Card>
                </Col>
                {QRCodeCaptured && (
                  <Col>
                    <Card
                      style={{
                        width: "158px",
                        height: "auto",
                        marginBottom: "10px",
                        borderRadius: 15,
                        padding: "10px 12px 3px 12px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {capturedFaceCompareImage ? (
                        <Card.Img
                          style={{
                            maxWidth: "100%",
                            height: "auto",
                            cursor: "pointer",
                            borderRadius: 10,
                          }}
                          variant="top"
                          src={capturedFaceCompareImage}
                          onClick={() => handleShow(capturedFaceCompareImage)}
                        />
                      ) : (
                        <Card.Img
                          onClick={() => setStatusSystem("guestFaceCompare")}
                          style={{
                            maxWidth: "71%",
                            height: "auto",
                            cursor: "pointer",
                            borderRadius: 10,
                          }}
                          variant="top"
                          src={NOIMG}
                        />
                      )}

                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip
                            id="tooltip-print"
                            style={{ fontSize: "10px" }}
                          >
                            Click to add/edit
                          </Tooltip>
                        }
                      >
                        <div
                          style={{
                            textAlign: "center",
                            padding: "0",
                            float: "left",
                            cursor: "pointer",
                          }}
                          onClick={() => setStatusSystem("guestFaceCompare")}
                        >
                          <span
                            style={{
                              fontWeight: "bold",
                              fontSize: "14px",
                              display: "block",
                              marginTop: "3px",
                              cursor: "pointer",
                            }}
                          >
                            Face Compare
                          </span>
                        </div>
                      </OverlayTrigger>
                    </Card>
                  </Col>
                )}
              </Row>
              <Row style={{ marginBottom: "8px" }}>
                <Col style={{ paddingRight: "0px" }}>
                  <Card
                    style={{
                      width: "158px",
                      height: "auto",
                      marginBottom: "10px",
                      borderRadius: 15,
                      padding: "10px 12px 3px 12px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {capturedIDImage ? (
                      <Card.Img
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          cursor: "pointer",
                          borderRadius: 10,
                        }}
                        onClick={() => handleShow(capturedIDImage)}
                        variant="top"
                        src={capturedIDImage}
                      />
                    ) : (
                      <Card.Img
                        style={{
                          maxWidth: "70%",
                          height: "auto",
                          cursor: "pointer",
                          borderRadius: 10,
                        }}
                        variant="top"
                        src={NOIMG}
                      />
                    )}
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip
                          id="tooltip-print"
                          style={{ fontSize: "10px" }}
                        >
                          Click to add/edit
                        </Tooltip>
                      }
                    >
                      <div
                        style={{
                          textAlign: "center",
                          padding: "0",
                          float: "left",
                        }}
                      >
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            display: "block",
                            marginTop: "3px",
                            cursor: "pointer",
                          }}
                          onClick={() => setStatusSystem("guestID")}
                        >
                          ID Card
                        </span>
                      </div>
                    </OverlayTrigger>
                  </Card>
                </Col>

                {QRCodeCaptured && (
                  <Col>
                    <Card
                      style={{
                        width: "158px",
                        height: "auto",
                        marginBottom: "10px",
                        borderRadius: 15,
                        padding: "10px 12px 3px 12px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {capturedIDCompareImage ? (
                        <Card.Img
                          style={{
                            maxWidth: "100%",
                            height: "auto",
                            cursor: "pointer",
                            borderRadius: 10,
                          }}
                          variant="top"
                          src={capturedIDCompareImage}
                          onClick={() => handleShow(capturedIDCompareImage)}
                        />
                      ) : (
                        <Card.Img
                          onClick={() => setStatusSystem("guestIDCompare")}
                          style={{
                            maxWidth: "71%",
                            height: "auto",
                            cursor: "pointer",
                            borderRadius: 10,
                          }}
                          variant="top"
                          src={NOIMG}
                        />
                      )}

                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip
                            id="tooltip-print"
                            style={{ fontSize: "10px" }}
                          >
                            Click to add/edit
                          </Tooltip>
                        }
                      >
                        <div
                          style={{
                            textAlign: "center",
                            padding: "0",
                            float: "left",
                            cursor: "pointer",
                          }}
                          onClick={() => setStatusSystem("guestIDCompare")}
                        >
                          <span
                            style={{
                              fontWeight: "bold",
                              fontSize: "14px",
                              display: "block",
                              marginTop: "3px",
                              cursor: "pointer",
                            }}
                          >
                            ID Compare
                          </span>
                        </div>
                      </OverlayTrigger>
                    </Card>
                  </Col>
                )}
              </Row>
              <Row style={{ marginBottom: "8px" }}>
                <Col style={{ paddingRight: "0px" }}>
                  <Card
                    style={{
                      width: "158px",
                      height: "auto",
                      marginBottom: "10px",
                      borderRadius: 15,
                      padding: "10px 12px 3px 12px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {capturedLuggageImage ? (
                      <Card.Img
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          cursor: "pointer",
                          borderRadius: 10,
                        }}
                        variant="top"
                        src={capturedLuggageImage}
                        onClick={() => handleShow(capturedLuggageImage)}
                      />
                    ) : (
                      <Card.Img
                        style={{
                          maxWidth: "70%",
                          height: "auto",
                          cursor: "pointer",
                          borderRadius: 10,
                        }}
                        variant="top"
                        src={NOIMG}
                      />
                    )}
           
                    <div
                      style={{
                        textAlign: "center",
                        padding: "0",
                        float: "left",
                        cursor: "pointer",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          display: "block",
                          marginTop: "3px",
                          cursor: "pointer",
                        }}
                        onClick={() => setStatusSystem("guestLuggage")}
                      >
                        Item
                      </span>
                    </div>
                  
                  </Card>
                </Col>
                <Col>
                  <Card
                    style={{
                      width: "158px",
                      height: "auto",
                      marginBottom: "10px",
                      borderRadius: 15,
                      padding: "10px 12px 3px 12px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {QRCodeCaptured ? (
                      <Card.Img
                        style={{
                          width: "68%",
                          height: "auto",
                          cursor: "pointer",
                        }}
                        variant="top"
                        src={guestInfo["linkqr"]}
                        onClick={() => handleShow(guestInfo["linkqr"])}
                      />
                    ) : (
                      <Card.Img
                        style={{
                          width: "68%",
                          height: "auto",
                          cursor: "pointer",
                        }}
                        variant="top"
                        src={
                          "https://png.pngtree.com/png-vector/20190120/ourmid/pngtree-info-vector-icon-png-image_470297.jpg"
                        }
                      />
                    )}

                    <div
                      style={{
                        textAlign: "center",
                        padding: "2%",
                        float: "left",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          display: "block",
                          marginTop: "3px",
                          cursor: "pointer",
                        }}
                      >
                        QR Code
                      </span>
                    </div>
                  </Card>
                </Col>
              </Row>
            </Col>

            {/* Col for content */}
            <Col
              //lg={8}
              className="scrollable-column"
              style={{
                padding: "0px 0px 0px 14px",
                maxWidth: "62%",
              
              }}
            >
              {/* Div for header */}
              <div
                style={{
                  position: "sticky",
                  padding: "25px 10px 10px 10px",
                  top: 0,
                  zIndex: 1,
                  width: "95%",
                  backgroundColor: "#E4C17D",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#fafafa",
                    borderRadius: 10,
                    paddingLeft: "2px",
                  }}
                >
                  {/* Div for badges */}
                  <div
                    style={{
                      width: "100%",

                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        marginBottom: "2%",
                        marginRight: "2%",
                        marginLeft: "0",
                        fontSize: "13px",
                        fontWeight: "bold",
                        cursor: "pointer",
                        padding: "9px 15px 8px 15px",
                        color: "#000",
                        margin: 0,
                        borderTopLeftRadius: "8px",
                        borderBottomLeftRadius: "8px",
                        //backgroundColor: "#9B743D !important",
                      }}
                      className={
                        depositeActive ? "custom-badge-active" : "custom-badge"
                      }
                      //bg={depositeActive ? "warning" : "secondary"}
                      onClick={() => debouncedGetListDeposite()}
                    >
                      Deposite
                    </span>
                    <span
                      style={{
                        marginBottom: "2%",
                        marginRight: "2%",
                        fontSize: "13px",
                        fontWeight: "bold",
                        cursor: "pointer",
                        padding: "9px 15px 8px 15px",
                        color: "#000",
                        margin: 0,
                        //backgroundColor: "#9B743D !important",
                      }}
                      className={
                        releaseActive ? "custom-badge-active" : "custom-badge"
                      }
                      onClick={() => debouncedGetListRelease()}
                    >
                      Release
                    </span>
                    <span
                      style={{
                        marginBottom: "2%",
                        marginRight: "2%",
                        fontSize: "13px",
                        fontWeight: "bold",
                        cursor: "pointer",
                        padding: "9px 15px 8px 15px",
                        color: "#000",
                        margin: 0,
                        //backgroundColor: "#9B743D !important",
                      }}
                      className="custom-badge"
                      onClick={() => history.push("/report")}
                    >
                      Report
                    </span>
                    <span
                      style={{
                        marginBottom: "2%",
                        marginRight: "2%",
                        fontSize: "13px",
                        fontWeight: "bold",
                        cursor: "pointer",
                        padding: "9px 15px 8px 15px",
                        color: "#000",
                        margin: 0,
                        //backgroundColor: "#9B743D !important",
                      }}
                      className="custom-badge"
                      onClick={() => doLogout()}
                    >
                      Logout
                    </span>
                  </div>

                  {/* Div for right-aligned content */}
                  <div style={{ width: "860px", marginTop: "0px" }}>
                    <div
                      style={{
                        position: "relative",
                        width: "100%",
                        paddingRight: "0px",
                      }}
                    >
                      <Form.Control
                        size="sm"
                        //className="mb-3"
                        ref={searchInputRef}
                        placeholder="Search Guest Name"
                        onChange={(e) => setSearchGuest(e.target.value)}
                        style={{
                          //borderRadius: 10,
                          outline: "none",
                          boxShadow: "none",
                          border: "1px solid #ced4da",
                          height: "40px",
                          padding: "20px 0px 20px 15px", // Add padding to the right to make space for the icon
                        }}
                      />
                      <i
                        className="bi bi-search"
                        style={{
                          position: "absolute",
                          right: "16px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          color: "#999",
                        }}
                      ></i>
                    </div>
                  </div>
                </div>
              </div>

              {/* GuestList */}
              <Card
                style={{
                  backgroundColor: "#E4C17D",
                  color: "#fff",
                  height: "740px",
                  border: "none",
                }}
              >
                <Card.Body style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                  <div
                    style={{
                      height: "470px",
                      paddingBottom: "8%",
                      marginTop: "0px",
                      paddingTop: "8px",
                    }}
                  >
                    {loading ? (
                      <div
                        className="d-flex flex-column justify-content-center align-items-center"
                        style={{ minHeight: "200px" }}
                      >
                        <div className="spinner-border" role="status">
                          <span className="sr-only"></span>
                        </div>
                        <div style={{ marginTop: "10px" }}>Loading...</div>
                      </div>
                    ) : (
                      <Row
                        className="ps-2"
                        style={{ borderLeft: "1px solid #000" }}
                      >
                        {/* loop for deposite list */}
                        {filteredArray.map((v, k) => (
                          <Col
                            key={k}
                            md={6}
                            lg={6}
                            className="mb-2"
                            style={{ maxWidth: "252px" }}
                            onClick={() => getDetailGuest(v.unique_num)}
                          >
                            <Card
                              style={{
                                minWidth: "100px",
                                maxWidth: "220px",
                                marginBottom: "16px",
                                borderRadius: 15,
                                height: v.checkout_item ? "215px" : "200px",
                                color: v.checkout_item ? "#000" : "#fff",
                                backgroundColor: v.checkout_item
                                  ? "#C6FFC6"
                                  : "#fafafa",
                              }}
                            >
                              <Card.Img
                                style={{
                                  width: "auto",
                                  height: "155px",
                                  padding: "10px",
                                  paddingBottom: "2px",
                                  borderRadius: 20,
                                }}
                                variant="top"
                                src={v.guest_img}
                              />
                              <div
                                style={{
                                  textAlign: "center",
                                  padding: "2%",
                                  float: "left",
                                }}
                              >
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "12px",
                                    display: "block",
                                    marginBottom: "2px",
                                    marginTop: "0px",
                                    color: "#000",
                                  }}
                                >
                                  {v.guest_name}
                                </span>
                                <span
                                  style={{
                                    fontSize: "10px",
                                    //fontStyle: "italic",
                                    display: "block",
                                    marginBottom: "1px",
                                    color: "#7f7f7f",
                                  }}
                                >
                                  {moment(v.checkin_item).format("lll")}
                                </span>
                                {v.updated_at && (
                                  <span
                                    style={{
                                      fontSize: "10px",
                                      //fontStyle: "italic",
                                      display: "block",
                                      marginBottom: "1px",
                                    }}
                                  >
                                    {moment(v.updated_at).format("lll")}
                                  </span>
                                )}
                              </div>
                            </Card>
                          </Col>
                        ))}
                      </Row>
                    )}
                  </div>
                </Card.Body>
              </Card>
            </Col>

            {/* Col for form */}
            <Col
              style={{
                marginTop: "0px",
                backgroundColor: "#fafafa",
                padding: "0px 35px 0px 30px",
                maxWidth: "22%",
              }}
            >
              {/* Row for user info */}
              <Row>
                <div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      paddingTop: "20px",
                    }}
                  >
                    <img
                      src={LOGO2}
                      alt="Ashley Hotel Group"
                      style={{
                        width: "100px",
                        height: "auto",
                      }}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: "5px",
                    }}
                  >
                    <Card.Text
                      style={{
                        fontWeight: "500",
                        color: "#B0B0AB",
                        marginBottom: "2px",
                        fontSize: "12px",
                      }}
                    >
                      Operator
                    </Card.Text>
                    <Card.Text
                      style={{
                        fontWeight: "500",
                        color: "#B0B0AB",
                        marginBottom: 0,
                        fontSize: "12px",
                      }}
                    >
                      Date : {moment().format("ll")}
                    </Card.Text>
                  </div>

                  <Card.Title
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      marginBottom: "8px",
                    }}
                  >
                    {user.name}
                  </Card.Title>
                  <Card.Text
                    style={{
                      fontWeight: "500",
                      color: "#B0B0AB",
                      marginBottom: "0px",
                      fontSize: "12px",
                    }}
                  >
                    Hotel
                  </Card.Text>
                  <Card.Title
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      marginBottom: "4px",
                    }}
                  >
                    {user.nm_hotel}
                  </Card.Title>
                </div>
              </Row>
              <hr
                style={{
                  border: "2px solid #ccc",
                  width: "100%",
                  marginTop: "8px",
                  marginBottom: "15px",
                }}
              />
              <Row style={{ marginBottom: "0px" }}>
                <Col lg={6}>
                  <div className="d-grid gap-1">
                    <Button
                      onClick={() => addNewItem()}
                      className="btn btl-lg"
                      variant="warning"
                    >
                      <i className="bi bi-bag-plus"></i>
                    </Button>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="d-grid gap-1">
                    <Button
                      variant="secondary"
                      onClick={() => openQRScanner()}
                      className="btn btl-lg"
                      
                    >
                      <i className="bi bi-qr-code-scan"></i>
                    </Button>
                  </div>
                </Col>
              </Row>

              {statusSystem === "qrScan" && (
                <Row>
                  <Col lg={12}>
                    <div
                      style={{
                        position: "relative",
                        width: "100%",
                        marginTop: "10%",
                      }}
                    >
                      {/* Adjust the width and height accordingly */}
                      <QrReader
                        // delay={800}
                        onError={handleError}
                        onScan={handleScan}
                        style={{ width: "100%", height: "800" }}
                      />

                      <div style={{ position: "absolute" }}>
                        <span
                          style={{
                            color: "red",
                            fontSize: "10px",
                            fontWeight: "italic",
                          }}
                        >
                          * Direct the QR from the guest to the camera for scanning
                        </span>
                      </div>
                    </div>
                  </Col>
                </Row>
              )}

              {statusSystem === "standby" && (
                <Row>
                  <Col
                    lg={12}
                    className="text-center"
                    style={{ marginTop: "20%" }}
                  >
                    <img width={100} src={LOADING} className="img-fluid" />
                  </Col>
                </Row>
              )}

              {/* CAMERA GUEST FACE DISPLAY */}
              {statusSystem === "guestface" && (
                <Row>
                  <Col lg={12}>
                    <div
                      style={{
                        position: "relative",
                        width: "100%",
                        height: "100px",
                        marginTop: "10%",
                      }}
                    >
                      {/* Adjust the width and height accordingly */}
                      <Webcam
                        audio={false}
                        screenshotFormat="image/jpeg"
                        ref={webcamFaceRef}
                        videoConstraints={videoConstraints}
                        style={{ width: "100%", height: "800" }}
                      />

                      <div
                        style={{
                          position: "absolute",
                          top: "30%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      >
                        <span
                          style={{
                            color: "#000",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          Guest Face
                        </span>
                      </div>

                      {/* Button at Center Bottom */}
                      <div
                        style={{
                          position: "absolute",
                          top: "220%",
                          left: "50%",
                          transform: "translateX(-50%)",
                          marginBottom: "10px",
                        }}
                      >
                        <Button
                          variant="warning"
                          style={{ width: "60px", height: "40px" }}
                          onClick={() => captureFace()}
                        >
                          <i class="bi bi-camera"></i>
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              )}

              {/* CAMERA GUEST ID DISPLAY */}
              {statusSystem === "guestID" && (
                <Row>
                  <Col lg={12}>
                    <div
                      style={{
                        position: "relative",
                        width: "100%",
                        height: "100px",
                        marginTop: "10%",
                      }}
                    >
                      {/* Adjust the width and height accordingly */}
                      <Webcam
                        audio={false}
                        screenshotFormat="image/jpeg"
                        ref={webcamIDCardRef}
                        videoConstraints={videoConstraints}
                        style={{ width: "100%", height: "800" }}
                      />

                      <div
                        style={{
                          position: "absolute",
                          top: "30%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      >
                        <span
                          style={{
                            color: "#000",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          Guest ID Card
                        </span>
                      </div>

                      {/* Button at Center Bottom */}
                      <div
                        style={{
                          position: "absolute",
                          top: "220%",
                          left: "50%",
                          transform: "translateX(-50%)",
                          marginBottom: "10px",
                        }}
                      >
                        <Button
                          variant="warning"
                          style={{ width: "60px", height: "40px" }}
                          onClick={() => captureIDCard()}
                        >
                          <i class="bi bi-camera"></i>
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              )}

              {/* CAMERA GUEST LUGGAGE DISPLAY */}
              {statusSystem === "guestLuggage" && (
                <Row>
                  <Col lg={12}>
                    <div
                      style={{
                        position: "relative",
                        width: "100%",
                        height: "100px",
                        marginTop: "10%",
                      }}
                    >
                      {/* Adjust the width and height accordingly */}
                      <Webcam
                        audio={false}
                        screenshotFormat="image/jpeg"
                        ref={webcamLuggageRef}
                        videoConstraints={videoConstraints}
                        style={{ width: "100%", height: "800" }}
                      />

                      <div
                        style={{
                          position: "absolute",
                          top: "30%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      >
                        <span
                          style={{
                            color: "#000",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                           Item
                        </span>
                      </div>

                      {/* Button at Center Bottom */}
                      <div
                        style={{
                          position: "absolute",
                          top: "220%",
                          left: "50%",
                          transform: "translateX(-50%)",
                          marginBottom: "10px",
                        }}
                      >
                        <Button
                          variant="warning"
                          style={{ width: "60px", height: "40px" }}
                          onClick={() => captureLuggageItem()}
                        >
                          <i class="bi bi-camera"></i>
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              )}

              {/* CAMERA GUEST FACE COMPARE DISPLAY */}
              {statusSystem === "guestFaceCompare" && (
                <Row>
                  <Col lg={12}>
                    <div
                      style={{
                        position: "relative",
                        width: "100%",
                        height: "100px",
                        marginTop: "10%",
                      }}
                    >
                      {/* Adjust the width and height accordingly */}
                      <Webcam
                        audio={false}
                        screenshotFormat="image/jpeg"
                        ref={webcamFaceCompareRef}
                        videoConstraints={videoConstraints}
                        style={{ width: "100%", height: "800" }}
                      />

                      <div
                        style={{
                          position: "absolute",
                          top: "30%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      >
                        <span
                          style={{
                            color: "#000",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          Face Compare
                        </span>
                      </div>

                      {/* Button at Center Bottom */}
                      <div
                        style={{
                          position: "absolute",
                          top: "220%",
                          left: "50%",
                          transform: "translateX(-50%)",
                          marginBottom: "10px",
                        }}
                      >
                        <Button
                          variant="warning"
                          style={{ width: "60px", height: "40px" }}
                          onClick={() => captureFaceCompare()}
                        >
                          <i class="bi bi-camera"></i>
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              )}

              {/* CAMERA GUEST ID COMPARE DISPLAY */}
              {statusSystem === "guestIDCompare" && (
                <Row>
                  <Col lg={12}>
                    <div
                      style={{
                        position: "relative",
                        width: "100%",
                        height: "100px",
                        marginTop: "10%",
                      }}
                    >
                      {/* Adjust the width and height accordingly */}
                      <Webcam
                        audio={false}
                        screenshotFormat="image/jpeg"
                        ref={webcamIDCompareRef}
                        videoConstraints={videoConstraints}
                        style={{ width: "100%", height: "800" }}
                      />

                      <div
                        style={{
                          position: "absolute",
                          top: "30%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      >
                        <span
                          style={{
                            color: "#000",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          ID Compare
                        </span>
                      </div>

                      {/* Button at Center Bottom */}
                      <div
                        style={{
                          position: "absolute",
                          top: "220%",
                          left: "50%",
                          transform: "translateX(-50%)",
                          marginBottom: "10px",
                        }}
                      >
                        <Button
                          variant="warning"
                          style={{ width: "60px", height: "40px" }}
                          onClick={() => captureIDCompare()}
                        >
                          <i class="bi bi-camera"></i>
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              )}

              {/* FORM INFORMATION GUEST */}
              {statusSystem === "formInformation" && (
                <Row className="mt-2">
                  <Form onSubmit={handleGenerateQR}>
                    <Form.Group className="mb-2">
                      {guestID ? (
                        <Form.Label
                          style={{ fontSize: "12px", color: "GrayText" }}
                        >
                          # {guestInfo["guestName"]}
                        </Form.Label>
                      ) : (
                        <Form.Label style={{ fontSize: "12px" }}>
                          *Guest
                        </Form.Label>
                      )}

                      <Form.Select
                        required
                        style={{ cursor: "pointer", fontSize: "14px" }}
                        className="form-select form-select-sm"
                        onChange={(e) => handleGuestInformation(e)}
                      >
                        <option
                          value={JSON.stringify({
                            GuestName: guestInfo["guestName"],
                            roomNo: guestInfo["roomNo"],
                          })}
                          style={{ fontSize: "14px" }}
                        >
                          {guestInfo["guestName"]}
                        </option>
                        <option
                          value={JSON.stringify({
                            GuestName: "Visitor",
                            roomNo: "0",
                          })}
                          style={{ fontSize: "14px" }}
                        >
                          Visitor
                        </option>
                        {guestInHouse.map((v, k) => (
                          <option
                            value={JSON.stringify({
                              GuestName: v.GuestName,
                              roomNo: v.roomNo,
                            })}
                            key={k}
                            style={{ fontSize: "14px" }}
                          >
                            {v.GuestName}{" "}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>

                    <Row>
                      <Col>
                        <Form.Group className="mb-2">
                          <Form.Label style={{ fontSize: "14px" }}>
                            *Room Number
                          </Form.Label>
                          <Form.Control
                            className="form-control form-control-sm"
                            type="text"
                            value={guestInfo["roomNo"]}
                            style={{ fontSize: "14px" }}
                            onChange={(e) =>
                              setGuestInfo({
                                ...guestInfo,
                                ["roomNo"]: e.target.value,
                              })
                            }
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-2">
                          <Form.Label style={{ fontSize: "14px" }}>
                            *Vehicle No
                          </Form.Label>
                          <Form.Control
                            type="text"
                            className="form-control form-control-sm"
                            required
                            style={{ fontSize: "14px" }}
                            value={guestInfo["vehicleNo"]}
                            onChange={(e) =>
                              setGuestInfo({
                                ...guestInfo,
                                ["vehicleNo"]: e.target.value,
                              })
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Form.Group className="mb-2">
                      <Form.Label style={{ fontSize: "14px" }}>Info</Form.Label>
                      <Form.Control
                        as="textarea"
                        className="form-control form-control-sm"
                        rows={3}
                        value={guestInfo["info"]}
                        style={{ fontSize: "14px" }}
                        onChange={(e) =>
                          setGuestInfo({
                            ...guestInfo,
                            ["info"]: e.target.value,
                          })
                        }
                      />
                    </Form.Group>

                    <Form.Group className="mb-4">
                      <Form.Label style={{ fontSize: "14px" }}>
                        *WhatsApp Number
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="form-control form-control-sm"
                        required
                        style={{ fontSize: "14px" }}
                        placeholder="0811XXXXXXXX"
                        value={guestInfo["whatsappNo"]}
                        onChange={(e) =>
                          setGuestInfo({
                            ...guestInfo,
                            ["whatsappNo"]: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                    {QRCodeCaptured === null ? (
                      <div className="d-grid">
                        <Button variant="warning" type="submit">
                          Generate QR Code
                        </Button>
                      </div>
                    ) : (
                      <div className="d-grid" style={{ border: "none" }}>
                        <ButtonGroup
                          aria-label="Basic example"
                          style={{ height: "38px" }}
                        >
                          <Button
                            className="custom-button"
                            onClick={() => doUpdateDetailGuest()}
                            style={{ fontSize: "13px" }}
                          >
                            <i class="bi bi-person-badge"></i> Update
                          </Button>
                          <Button
                            variant="success"
                            onClick={() => sendQRtoGuest()}
                            style={{ border: "none", fontSize: "13px" }}
                          >
                            <i class="bi bi-whatsapp"></i> Send QR
                          </Button>
                          <Button
                            variant="primary"
                            onClick={() => setShowModalPrint(true)}
                            style={{ border: "none", fontSize: "13px" }}
                          >
                            <i class="bi bi-printer"></i> Print
                          </Button>
                        </ButtonGroup>
                      </div>
                    )}

                    {capturedFaceCompareImage !== null &&
                      capturedIDCompareImage !== null && (
                        <div className="d-grid mt-2" style={{ height: "40px" }}>
                          <Button
                            style={{ fontSize: "14px" }}
                            className="custom-button-light"
                            onClick={() => doReleaseItem()}
                          >
                            Release Item
                          </Button>
                        </div>
                    )}
                  </Form>
                </Row>
              )}
            </Col>
          </Row>
        </Container>
      </div>

      <Modal
        show={showModalPrint}
        onHide={() => setShowModalPrint(false)}
      >
        <Modal.Body>
          <center>
            <div id="printArea">
              <div
                style={{
                  border: "0.2px solid grey",
                  borderRadius: "12px",
                  padding: "2%",
                  width: "400px",
                  height: "520px",
                }}
              >
                <center>
                  <img width={50} src={user.logo_hotel} />
                  <p
                    style={{
                      padding: "2%",
                      fontSize: "12px",
                      fontWeight: "900",
                    }}
                  >
                    CAR RECEIPT
                  </p>
                </center>
                <Table size="sm">
                  <tbody>
                    <tr>
                      <td style={{ fontSize: "14px", fontFamily: "Arial" }}>
                        Name
                      </td>
                      <td style={{ fontSize: "12px", fontWeight: "bolder" }}>
                        :
                      </td>
                      <td
                        style={{
                          fontSize: "20px",
                          fontWeight: "900",
                          fontFamily: "Arial",
                        }}
                      >
                        {guestInfo["guestName"]}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ fontSize: "14px", fontFamily: "Arial" }}>
                        Check-In
                      </td>
                      <td style={{ fontSize: "12px", fontWeight: "bolder" }}>
                        :
                      </td>
                      <td style={{ fontSize: "20px", fontFamily: "Arial" }}>
                        {moment(guestInfo["ci"]).format("lll")}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ fontSize: "14px", fontFamily: "Arial" }}>
                        Check-Out
                      </td>
                      <td style={{ fontSize: "12px", fontWeight: "bolder" }}>
                        :
                      </td>
                      <td style={{ fontSize: "20px", fontFamily: "Arial" }}>
                        {moment(guestInfo["co"]).format("lll")}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ fontSize: "14px", fontFamily: "Arial" }}>
                        Vehicle
                      </td>
                      <td style={{ fontSize: "12px", fontWeight: "bolder" }}>
                        :
                      </td>
                      <td style={{ fontSize: "20px", fontFamily: "Arial" }}>
                        {guestInfo["vehicleNo"]}
                      </td>
                    </tr>

                    <tr>
                      <td style={{ fontSize: "14px", fontFamily: "Arial" }}>
                        Room
                      </td>
                      <td style={{ fontSize: "12px", fontWeight: "bolder" }}>
                        :
                      </td>
                      <td style={{ fontSize: "20px", fontFamily: "Arial" }}>
                        {guestInfo["roomNo"]}
                      </td>
                    </tr>

                    <tr>
                      <td style={{ fontSize: "14px", fontFamily: "Arial" }}>
                        Phone
                      </td>
                      <td style={{ fontSize: "12px", fontWeight: "bolder" }}>
                        :
                      </td>
                      <td style={{ fontSize: "20px", fontFamily: "Arial" }}>
                        {guestInfo["whatsappNo"]}
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <div
                  style={{
                    fontSize: "14px",
                    fontFamily: "Arial",
                    marginTop: "12px",
                    textAlign: "justify",
                    fontWeight: "bolder",
                  }}
                >
                  The Hotel is no longer responsible for anything after the
                  vehicle returned. This Slip is proof that the key vehicle have
                  been return
                </div>
              </div>
            </div>
          </center>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowModalPrint(false)}
          >
            Closedd
          </Button>

          <Button variant="secondary" onClick={() => doPrint()}>
            Print
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal to show image */}
      <Modal className="modal-lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Image Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={modalImage}
            alt="Modal Image"
            style={{ width: "100%", height: "auto" }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <style>
        {`
          .custom-button {
            background-color: #9B743D !important;
            border: none;
          }
          .custom-button:hover {
            background-color: #7F5F32 !important; /* Darker shade */
          }
          .custom-badge-active {
            background-color: #E0E0E0 !important;
          }
          .custom-badge {
            background-color: #fafafa !important;
          }
          .custom-badge:hover {
            background-color: #E0E0E0 !important;
          }
          .custom-button-light {
            background-color: #E4C17D;
            color: #000;
            border: none;
          }
          .custom-button-light:hover {
            background-color: #9B743D; /* Darker shade */
          }
          .scrollable-column {
            max-height: 100vh; /* or any height you want to set */
            overflow-y: auto;
          }
          .scrollable-column::-webkit-scrollbar {
            display: none; /* for Chrome, Safari, and Opera */
          }
          .scrollable-column {
            -ms-overflow-style: none; /* for Internet Explorer and Edge */
            scrollbar-width: none; /* for Firefox */
          
        `}
      </style>
    </div>
  );
}

export default Dashboard;
