import React, { useState, useEffect, useCallback, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Container,
  Row,
  Form,
  Col,
  Card,
  Button,
  Badge,
} from "react-bootstrap";
import Swal from "sweetalert";
import "sweetalert2/dist/sweetalert2.css";
import LOGO from "../assets/2.png";
import axios from "../utils/axios";
import moment from "moment";
import { getToken, getUser } from "../utils/Common";
import DataTable from "react-data-table-component";

function Report() {
  const history = useHistory();
  const token = getToken();
  const user = getUser();
  const [dataGuest, setDataGuest] = useState([]);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);

  const columns = [
    {
      name: "No",
      width: "100px",
      selector: (row) => row.unique_num,
    },
    {
      name: "Name",
      width: "180px",
      wrap: "true",
      selector: (row) => row.guest_name,
    },
    {
      name: "Phone",
      width: "150px",
      selector: (row) => row.phoneNo,
    },

    {
      name: "Room",
      width: "70px",
      selector: (row) => row.guest_room,
    },
    {
      name: "Information",
      width: "100px",
      selector: (row) => row.noPol,
    },
    {
      name: "CheckIn",
      width: "120px",
      wrap: "true",
      selector: (row) => moment(row.created_at).format("lll"),
    },

    {
      name: "CheckOut",
      width: "120px",
      wrap: "true",
      selector: (row) => moment(row.updated_at).format("lll"),
    },
    {
      name: "Face 1",
      button: true,
      width: "90px",
      ignoreRowClick: true,
      allowOverflow: true,
      cell: (row) => <img style={{ width: "80px" }} src={row.guest_img} />,
    },
    {
      name: "Face 2",
      button: true,
      width: "90px",
      ignoreRowClick: true,
      allowOverflow: true,
      cell: (row) => (
        <img style={{ width: "80px" }} src={row.guest_img_compare} />
      ),
    },
    {
      name: "ID Card",
      button: true,
      width: "90px",
      ignoreRowClick: true,
      allowOverflow: true,
      cell: (row) => <img style={{ width: "80px" }} src={row.guest_ID_img} />,
    },

    {
      name: "Item",
      button: true,
      width: "90px",
      ignoreRowClick: true,
      allowOverflow: true,
      cell: (row) => <img style={{ width: "80px" }} src={row.guest_item_img} />,
    },

    {
      name: "QR Code",
      button: true,
      width: "90px",
      ignoreRowClick: true,
      allowOverflow: true,
      cell: (row) => <img style={{ width: "80px" }} src={row.linkqr} />,
    },
  ];

  const handleGenerateReport = async (e) => {
    e.preventDefault();
    const HID = user.hotel_id;
    try {
      console.log("dateTo: ", dateTo);
      const response = await axios.post(
        `/api/generate/report`,
        { HID, dateFrom, dateTo },
        { headers: { "content-type": "text/json" } }
      );

      console.log(response.data);
      setDataGuest(response.data);
    } catch (err) {
      console.log(err);

      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: err,
      });
    } finally {
      console.log(true);
      // getListDeposite();
    }
  };

  // useEffect(() => {

  // }, []);

  return (
    <Container className="mt-3">
      <Form onSubmit={handleGenerateReport}>
        <Row>
          <Col
            className="text-center"
            lg={1}
            onClick={() => history.push("/dashboard")}
          >
            <img width={50} src={user.logo_hotel} />
          </Col>
          <Col>
            <Form.Group>
              <Form.Label style={{ fontSize: "12px" }}>From Date</Form.Label>
              <Form.Control
                size="sm"
                required
                type="date"
                onChange={(e) => setDateFrom(e.target.value)}
              />
            </Form.Group>
          </Col>

          <Col>
            <Form.Group>
              <Form.Label style={{ fontSize: "12px" }}>To Date</Form.Label>
              <Form.Control
                size="sm"
                type="date"
                required
                placeholder="To Date"
                onChange={(e) => setDateTo(e.target.value)}
              />
            </Form.Group>
          </Col>

          <Col lg={1}>
            <div className="d-grid" style={{ marginTop: "28px" }}>
              <Button size="sm" variant="primary" type="submit">
                Search
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
      <hr></hr>
      <Row>
        <DataTable columns={columns} data={dataGuest} pagination />
      </Row>
    </Container>
  );
}

export default Report;
