import React, { useState, useEffect, useCallback, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Container,
  Row,
  Form,
  Col,
  Card,
  Button,
  Badge,
} from "react-bootstrap";

import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import LOGO from "../assets/2.png";
import axios from "../utils/axios";
import { setUserSession } from "../utils/Common";
function Login() {
  const history = useHistory();

  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const doLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `/api/login`,
        {
          email,
          password,
        },
        { headers: { "content-type": "text/json" } }
      );

      console.log(response.data);
      if (response.data.status === 200) {
        setUserSession(response.data.token, response.data.user);
        history.push("/dashboard");
      }
    } catch (err) {
      console.log(err);

      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: err,
      });
    } finally {
      console.log("Done");
    }
  };

  return (
    <div
      style={{
        overflow: "hidden",
        backgroundColor: "#333232",
        height: "100%",
        minHeight: "100vh",
      }}
    >
      <Card style={{ width: "360px", margin: "auto", marginTop: "120px" }}>
        <Card.Body>
          <center>
            <img width={130} src={LOGO}></img>
          </center>
          <Form onSubmit={doLogin}>
            <Form.Group className="mb-1">
              <Form.Label style={{ fontSize: "12px" }}>Email </Form.Label>
              <Form.Control
                size="sm"
                type="email"
                required
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-1">
              <Form.Label style={{ fontSize: "12px" }}>Password</Form.Label>
              <Form.Control
                size="sm"
                type={showPassword ? "text" : "password"}
                required
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-4" controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                onClick={() => setShowPassword(!showPassword)}
                label="Show Password"
                style={{ fontSize: "11px" }}
              />
            </Form.Group>

            <div className="d-grid">
              <Button variant="dark" type="submit">
                Submit
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
}

export default Login;
